import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';

const Header = ({ language, name, level, progress, photoUrl }) => {
    const t = (key) => translations[language][key] || key;

    return (
        <div className="container m-0 p-0 mt-4">
            <div className="row d-flex align-items-center justify-content-between text-center">
                <div className="col-4">
                    <Link
                        to="/Profile" className={`text-decoration-none text-white`}>
                        <div className="container profile position-relative">
                            <div className="d-flex">
                                <img src={photoUrl} className="me-0" alt="profile" />
                                <div className="level">{level}</div>
                                <div className='container'>
                                    <p className="name mb-1">{name || t('loading')}</p>
                                    <div className="loading-line">
                                        <div className="loaded" style={{ width: `${progress}%` }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-4">
                    <img src="https://web.mgc660.com/coinLogo.png" style={{ width: '100px' }} alt="coin logo" />
                </div>
                <div className="col-4">
                    <div className="aboutText">{t('about_token')}</div>
                </div>
            </div >
        </div >
    );
};

export default Header;
