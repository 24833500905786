import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer.js';
import Header from './Header.js';
import translations from './utils/translations';

const Airdrop = ({ language, id, name, level, progress, photoUrl }) => {
    const t = (key) => translations[language][key] || key;

    return (
        <div className="games container d-flex flex-column align-items-center">
            <Header language={language} name={name} level={level} progress={progress} photoUrl={photoUrl} />
            <div className='container header text-center'>
                <img src='https://web.mgc660.com/bigAirdrop.svg'></img>
                <h1 className=' ms-2'>{t('coming_soon')}</h1>
                <p className=' ms-2'>{t('complete_them_to_participate_in_the_airdrop_connect_wallet_to_withdraw_your_money')}
                </p>
                {/* <Link
                    to="/" className='text-decoration-none text-white'
                ><div className='submit d-flex align-items-center justify-content-center mt-5'>{t('back_to_game')}</div>
                </Link> */}
            </div>
            <Footer language={language} />
        </div>
    );
};

export default Airdrop;
