import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';


const Footer = ({ language }) => {
    const location = useLocation();
    const t = (key) => translations[language][key] || key;

    return (
        <div className="footer text-center py-2">
            <div className="d-flex justify-content-around">
                <Link
                    to="/"
                    className={`text-decoration-none text-white`}
                >
                    <div className={`${location.pathname === "/" ? "active-nav" : ""}`}>
                        <img src="https://web.mgc660.com/homemain.svg" alt="Main" />
                        <p>{t('main')}</p> {/* Translated text */}
                    </div>
                </Link>
                <Link
                    to="/earn"
                    className={`text-decoration-none text-white ${location.pathname === "/earn" ? "active-nav" : ""}`}
                >
                    <div>
                        <img src="https://web.mgc660.com/earn.svg" alt="Earn" />
                        <p>{t('earn')}</p>
                    </div>
                </Link>
                <Link
                    to="/friends"
                    className={`text-decoration-none text-white ${location.pathname === "/friends" ? "active-nav" : ""}`}
                >
                    <div>
                        <img src="https://web.mgc660.com/friends.svg" alt="Friends" />
                        <p>{t('friends')}</p>
                    </div>
                </Link>
                <Link
                    to="/airdrop"
                    className={`text-decoration-none text-white ${location.pathname === "/airdrop" ? "active-nav" : ""}`}
                >
                    <div>
                        <img src="https://web.mgc660.com/airdrop.svg" alt="Airdrop" />
                        <p>{t('airdrop')}</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Footer;