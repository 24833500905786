import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer.js';
import Header from './Header.js';
import translations from './utils/translations';

const API_URL = 'https://ftn.iotlab.am';

const tg = window.Telegram.WebApp;

const Friends = ({ language, id, name, level, progress, photoUrl }) => {
    const t = (key) => translations[language][key] || key;

    const referralLink = `https://t.me/ftntest_bot?start=${id}`;
    const referralText = `🎁 50,000 OK.FUN Tokens are already waiting for you at OKFUN! 😌 I invite you to join the game.`;

    const url = `https://t.me/share/url?url=${referralLink}&text=${encodeURIComponent(referralText)}`;

    const handleInviteClick = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(url);
        } else {
            window.open(url, '_blank');
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => alert(t('referral_link_copied')))
            .catch(err => alert(t('failed_to_copy_referral_link') + err));
    };

    function formatNumber(num) {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B'; // Billions
        }
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
        }
        if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Thousands
        }
        return num.toString(); // Less than 1000
    }

    const [referrals, setReferrals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReferrals = async () => {
            try {
                const response = await fetch(`${API_URL}/api/referrals/${id}`);
                const data = await response.json();

                if (data.referrals) {
                    setReferrals(data.referrals);
                } else {
                    console.error("No referrals found");
                }
            } catch (error) {
                console.error("Error fetching referrals:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
    }, [id]);

    if (loading) {
        return <p className='mt-4'>{t('loading')}</p>;
    }

    return (
        <div className='friends'>
            <div className="container d-flex flex-column align-items-center text-white">
                <Header language={language} name={name} level={level} progress={progress} photoUrl={photoUrl} />
                <img className='giftImage' src="https://web.mgc660.com/gift.svg" alt="gift" />
                <div className='container header mt-3 ms-2'>
                    <h1>{t('get_20')}</h1>
                </div>
                <div className="container mt-2 mb-2 inviteCard p-3">
                    <h3>{t('invite_a_friend')}</h3>
                    <div className="d-flex align-items-center">
                        <img src="https://web.mgc660.com/coin.svg" className="coin-icon me-1" alt="Coin Icon" />
                        <p className="me-2">5,000</p>
                        <span>{t('for_you_and_your_friend')}</span>
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <div className="card me-3" onClick={handleInviteClick}>{t('invite_a_friend')}</div>
                        <div className="copy d-flex align-items-center justify-content-center" onClick={handleCopyClick}>
                            <img src="https://web.mgc660.com/copy.svg" alt="Copy Icon" />
                        </div>
                    </div>
                </div>
                {referrals.length === 0 ? (
                    <p className='mt-4'>{t('no_referrals_found')}</p>
                ) : (
                    referrals.map((referral) => (
                        <div key={referral.id} className='container mt-2 friendCard d-flex align-items-center'>
                            <div className='position-relative  me-2'>
                                <img src={referral.photo_url || "https://web.mgc660.com/ppexample.jpeg"} className="profile" alt={referral.username} />
                                <div className="level">{referral.level}</div>
                            </div>
                            <div className='container d-flex flex-column justify-content-center'>
                                <p className="name mb-1">{referral.first_name || t('loading')}</p>
                                <div className="d-flex align-items-center justify-content-center mining">
                                    <img src='https://web.mgc660.com/coin.svg' className='coinIcon' alt="Coin" />
                                    <p> {formatNumber(Math.floor(referral.mining_rate))} / hour</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end ms-auto'>
                                <img src="https://web.mgc660.com/coin.svg" className='coinIcon me-1' alt="Coin" />
                                <p className='me-1'>+</p>
                                <p>{formatNumber(Math.floor(referral.coins))}</p>
                            </div>
                        </div>
                    ))
                )}
                <Footer language={language} />
            </div>
        </div>
    )
}

export default Friends;
