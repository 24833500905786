import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';


const Profile = ({ language, name, level, progress, photoUrl }) => {
    const t = (key) => translations[language][key] || key;

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            tg.BackButton.hide();
            window.history.back();
        });

        return () => {
            tg.BackButton.offClick();
        };
    }, []);

    return (
        <div className="info">
            <div className="container mt-5">
                <div className="card d-flex align-items-center justify-content-center">
                    <div className="container d-flex justify-content-center">
                        <img src={photoUrl} alt="Profile" />
                        <div className="container">
                            <h2>{name ? name : t('loading')}</h2>
                            <div className="loading-line">
                                <div className="loaded" style={{ width: `${progress}%` }}></div>
                                <div className="d-flex justify-content-between">
                                    <p className="m-0">{level} {t('level')}</p>
                                    <p className="m-0">{level + 1} {t('level')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="/Settings" className="text-decoration-none text-white">
                    <div className="card settings container d-flex align-items-start justify-content-center mt-3 p-0">
                        <div className="container d-flex align-items-center">
                            <img src="https://web.mgc660.com/settings.svg" className="icon me-3" alt="Settings Icon" />
                            <h3>{t('settings')}</h3>
                            <img src="https://web.mgc660.com/rightarrow.svg" className="arrow ms-auto me-1" alt="Arrow" />
                        </div>
                    </div>
                </Link>
                <p className="copyright">{t('copyright')}</p>
            </div>
        </div>
    );
};

export default Profile;
