const translations = {};

function importAll(r) {
    r.keys().forEach((key) => {
        const lang = key.replace('./', '').replace('.json', '');
        translations[lang] = r(key);
    });
}

importAll(require.context('../locales', false, /\.json$/));

export default translations;
