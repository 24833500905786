import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Earn from './Earn.js';
import Footer from './Footer.js';
import Friends from './Friends.js';
import Airdrop from './Airdrop.js';
import Profile from './Profile.js';
import Settings from './Settings.js';
import Header from './Header.js';
import Task from './Task.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import translations from './utils/translations';

const tg = window.Telegram.WebApp;

const API_URL = 'https://ftn.iotlab.am';


const App = () => {
  const [user, setUser] = useState(null);
  const [sound, setSound] = useState(true);
  const [taskNum, setTaskNum] = useState(0);
  const [vibration, setVibration] = useState(true);
  const [coins, setCoins] = useState(0);
  const [level, setLevel] = useState(0);
  const [progress, setProgress] = useState(0);
  const [incomeHour, setIncomeHour] = useState(0);
  const [incomeTap, setIncomeTap] = useState(1);
  const [energy, setEnergy] = useState(500);
  const [maxEnergy, setMaxEnergy] = useState(500);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('en');
  const t = (key) => translations[language][key] || key;

  useEffect(() => {
    tg.ready();
    console.log('Telegram Web App ready');
    const userData = tg.initDataUnsafe.user;
    // const userData = {
    //   id: 1074699355,
    //   username: 'davbaghdasaryan',
    //   first_name: 'Dav',
    //   photo_url: 'https://t.me/i/userpic/320/Yg0ESF1zRuff9eKMie3860DCrFtwATVKh-SA-bZ75BI.svg'
    // };
    setUser(userData);

    if (userData) {
      saveUser(userData);
      fetchTasksNum(userData.id);
      fetchUserStats(userData.id);
      fetchUserEnergy(userData.id);

      const intervalId = setInterval(() => {
        fetchUserStats(userData.id);
        fetchUserEnergy(userData.id);
      }, 3000); // 5000ms = 5 seconds

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const saveUser = async (user) => {
    try {
      const response = await fetch(`${API_URL}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });

      if (response.ok) {
        console.log('User saved successfully');
      } else {
        console.error('Failed to save user');
      }
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const fetchUserStats = async (userId) => {
    try {
      // Process mining on the backend
      const miningResponse = await fetch(`${API_URL}/api/users/mining`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userId }),
      });

      if (!miningResponse.ok) {
        console.error('Failed to process mining:', miningResponse.statusText);
        return;
      }

      const miningData = await miningResponse.json();
      console.log('Mining processed:', miningData);

      // Fetch updated user stats
      const statsResponse = await fetch(`${API_URL}/api/users/${userId}`);
      if (!statsResponse.ok) {
        console.error('Failed to fetch user stats:', statsResponse.statusText);
        return;
      }

      const userData = await statsResponse.json();

      // Update frontend state with the latest user stats
      setSound(userData.sound);
      setVibration(userData.vibration);
      setLanguage(userData.language);
      setLevel(userData.level);
      setProgress(userData.progress_bar);
      setCoins(userData.coins); // Update coins
      setEnergy(userData.energy); // Update energy
      setIncomeTap(userData.per_tap);
      setIncomeHour(userData.mining_rate);
      setMaxEnergy(userData.energy_limit);
      console.log('User stats updated:', userData);
    } catch (error) {
      console.error('Error syncing user data:', error);
      setError('Error syncing user data');
    }
  };


  const fetchUserEnergy = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/api/users/energy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userId }),
      });

      if (!response.ok) {
        console.error('Failed to sync energy:', response.statusText);
        return;
      }

      const data = await response.json();
      console.log('Energy synced:', data);

      // Fetch updated user stats to reflect new energy value
      const statsResponse = await fetch(`${API_URL}/api/users/${userId}`);
      if (!statsResponse.ok) {
        console.error('Failed to fetch user stats after energy sync:', statsResponse.statusText);
        return;
      }

      const userData = await statsResponse.json();
      setEnergy(userData.energy); // Update energy state
    } catch (error) {
      console.error('Error syncing energy:', error);
      setError('Error syncing energy');
    }
  };


  const fetchTasksNum = async (id) => {
    try {
      const response = await fetch(`${API_URL}/api/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      });
      const data = await response.json();

      if (response.ok) {
        setTaskNum(data.length);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };


  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B'; // Billions
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Thousands
    }
    return num.toString(); // Less than 1000
  }


  const vibrate = () => {
    if (tg.HapticFeedback && vibration) {
      // Trigger light impact feedback on tap
      tg.HapticFeedback.impactOccurred('light'); // You can use 'light', 'medium', 'heavy', etc.
    } else {
      console.log('HapticFeedback API not available');
    }
  };

  const [showPlusOne, setShowPlusOne] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const triggerAnimation = (e) => {
    vibrate();

    const rect = e.target.getBoundingClientRect();
    const clickX = e.clientX - rect.left + 70;
    const clickY = e.clientY - rect.top + 50;

    setPosition({ x: clickX, y: clickY });
    setShowPlusOne(true);
    setTimeout(() => setShowPlusOne(false), 1000);
  };

  const handleLogic = async () => {
    if (user && user.id) {
      try {
        const response = await fetch(`${API_URL}/api/tap`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: user.id }),
        });

        if (response.ok) {
          const data = await response.json();
          setCoins(prevCoins => prevCoins + data.reward);  // Increment coins by the reward from backend
          setEnergy(prevEnergy => data.remainingEnergy);  // Update remaining energy from backend
        } else {
          const errorData = await response.json();
          console.error('Error during tap:', errorData.message);
          setError(errorData.message);
        }
      } catch (error) {
        console.error('Error during tap request:', error);
        setError('Error during tap');
      }
    } else {
      console.error('User is not logged in or ID is missing');
    }
  };

  // Handle the click and call both functions
  const handleAvatarClick = (e) => {
    triggerAnimation(e);  // Trigger the animation
    handleLogic();        // Call your existing logic
  };


  ///////////

  function mapNumber(input, inMin, inMax, outMin, outMax) {
    if (input < inMin || input > inMax) {
      throw new Error("Input number is out of range!");
    }
    return ((input - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  const height = mapNumber(window.innerHeight, 600, 940, 10, 80);

  const MainStats = ({ coins, incomeHour, incomeTap }) => (
    <div className="main-stats text-center" style={{ marginTop: `${height}px` }}>
      <div className="container mt-2 d-flex flex-column align-items-center">
        <div className="d-flex align-items-center">
          <img src="https://web.mgc660.com/coin.svg" className="coin-icon me-2" alt="coin icon"></img>
          <h2>{coins}</h2> {/* Display dynamic coin count */}
        </div>
      </div>
      <div className="container mt-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <img className="me-2" src="https://web.mgc660.com/incomehour.svg" alt="income hour"></img>
            <p className="income"><span>{formatNumber(incomeHour)}</span> {t('income_hour')}</p>
          </div>
          <div className="d-flex align-items-center">
            <img className="me-2" src="https://web.mgc660.com/incometap.svg" alt="income tap"></img>
            <p className="income"><span>{incomeTap}</span> {t('income_tap')}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const LuckySpin = ({ energy, maxEnergy }) => (
    <div className="lucky-spin container d-flex align-items-center justify-content-between">
      <div className="d-flex flex-column h-100">
        <div className="energy d-flex align-items-center mt-auto">
          <img className="me-2" src="https://web.mgc660.com/energy.svg" alt="energy icon"></img>
          <p className="mb-0">{energy} <span>/</span> <span>{maxEnergy}</span></p>
        </div>
      </div>

      <div className="spinBox ms-auto">
        <img src="https://web.mgc660.com/luckyspin.svg" alt="lucky spin"></img>
        <p>{t('lucky_spin')}
        </p>
        <p className="">00:00:00</p>
      </div>
    </div>
  );

  /////////////////////


  const Avatar = ({ handleAvatarClick }) => (
    <div
      className="avatar text-center"
      style={{ marginTop: `${height}px` }}
      onClick={handleAvatarClick}
    >
      <img src={`https://web.mgc660.com/tigers/lvl${level}.png`} alt="Avatar" className="tap-image" />

      {/* Render the +1 text with dynamic position */}
      {showPlusOne && (
        <div
          className="plus"
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
        >
          +{incomeTap}
        </div>
      )}
    </div>
  );

  const Tasks = () => (
    <Link to="/Task" className={`tasks text-center d-flex align-items-center text-decoration-none text-white`} style={{ marginTop: `${height}px` }}>
      <div className='container d-flex align-items-center'>
        <img className="me-3" src="https://web.mgc660.com/tasks.svg"></img>
        <p className="me-3">{t('tasks')}
        </p>
        <div className="taskNumber">{taskNum}</div>
      </div>
    </Link>
  );

  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const togglePopup = async () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const referralLink = `https://t.me/ftntest_bot?start=${user?.id || '1111111111'}`;
  const referralText = `🎁 50,000 OK.FUN Tokens are already waiting for you at OKFUN! 😌 I invite you to join the game.`;

  const url = `https://t.me/share/url?url=${referralLink}&text=${encodeURIComponent(referralText)}`;

  const handleInviteClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralLink) // Copy the referral link to the clipboard
      .then(() => alert(t('referral_link_copied'))) // Using the translation for the success message
      .catch(err => alert(t('failed_to_copy_referral_link') + err)); // Using translation for error message
  };

  const Home = () => (
    <div className="home">
      <div className="container d-flex flex-column align-items-center text-white">
        <Header language={language}
          name={user?.first_name || 'Guest'}
          level={level}
          progress={progress}
          photoUrl={user?.photo_url || 'default-profile.png'}
        />
        <MainStats coins={coins} incomeHour={incomeHour} incomeTap={incomeTap} />
        <Avatar handleAvatarClick={handleAvatarClick} /> {/* Pass handleAvatarClick as prop */}
        <LuckySpin energy={energy} maxEnergy={maxEnergy} />
        <Tasks />
        <Footer language={language} />
      </div>
      {isPopupVisible && (
        <>
          <div className="overlay" onClick={togglePopup}></div>
          <div className="popup text-center">
            <img src="https://web.mgc660.com/close.svg" className='close' onClick={togglePopup}></img>
            <div className='container d-flex flex-column'>
              <img className="logo" src="https://web.mgc660.com/popupGift.svg"></img>
              <h3>Send a gift to a friend and earn even more!</h3>
              <p>Send free gifts to your friends and start earning 20% of each friend’s profits!</p>
              <div className="d-flex align-items-center mt-2">
                <div className='card me-3' onClick={handleInviteClick}>Invite a friend</div>
                <div className='copy d-flex align-items-center justify-content-center' onClick={handleCopyClick}>
                  <img src="https://web.mgc660.com/copy.svg"></img>
                </div>
              </div>
              <p className='mt-3 mb-3'>Don’t forget to collect the daily reward</p>
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/earn" element={<Earn language={language} id={user?.id || '1111111111'} coins={parseInt(coins)} incomeTap={incomeTap} energy={energy} maxEnergy={maxEnergy} />} />
        <Route path="/friends" element={<Friends language={language} id={user?.id || '1111111111'} name={user?.first_name || 'Guest'} level={level} progress={progress}
          photoUrl={user?.photo_url || 'default-profile.png'} />} />
        <Route path="/airdrop" element={<Airdrop language={language} id={user?.id || '1111111111'} name={user?.first_name || 'Guest'} level={level} progress={progress} photoUrl={user?.photo_url || 'default-profile.png'} />} />
        <Route path="/profile" element={<Profile language={language} name={user?.first_name || 'Guest'} level={level} progress={progress} photoUrl={user?.photo_url || 'default-profile.png'} />} />
        <Route path="/settings" element={<Settings language={language} id={user?.id || '1111111111'} />} />
        <Route path="/task" element={<Task language={language} id={user?.id || '1111111111'} />} />
      </Routes>
    </Router>
  );
};

export default App;
