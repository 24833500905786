import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import translations from './utils/translations';

const API_URL = 'https://ftn.iotlab.am';

const languageMap = {
    en: 'English',
    ru: 'Russian',
    hi: 'Hindi',
    pt: 'Portuguese',
    es: 'Spanish',
    id: 'Indonesian',
    uk: 'Ukrainian',
    tr: 'Turkish',
    vi: 'Vietnamese',
    fa: 'Persian',
};

const Settings = ({ language, id }) => {
    const [sound, setSound] = useState(false);
    const [vibration, setVibration] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const t = (key) => translations[language][key] || key;

    const fetchUserSettings = async (id) => {
        try {
            const statsResponse = await fetch(`${API_URL}/api/users/${id}`);
            if (!statsResponse.ok) {
                console.error('Failed to fetch user stats:', statsResponse.statusText);
                return;
            }

            const userData = await statsResponse.json();

            setSound(userData.sound);
            setVibration(userData.vibration);
        } catch (error) {
            console.error('Error syncing user data:', error);
        }
    };

    const updateLanguage = async (newLanguage) => {
        try {
            const updatedSettings = { language: newLanguage };

            const response = await fetch(`${API_URL}/api/users/${id}/language`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedSettings),
            });

            if (!response.ok) {
                console.error('Failed to update language:', response.statusText);
                return;
            }

            const data = await response.json();
            console.log('Language updated successfully:', data);
            setSelectedLanguage(newLanguage);
            if (id) {
                fetchUserSettings(id);
            }
        } catch (error) {
            console.error('Error updating language:', error);
        }
    };

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            tg.BackButton.hide();
            window.history.back();
        });

        if (id) {
            fetchUserSettings(id);
        }

        return () => {
            tg.BackButton.offClick();
        };
    }, [id]);

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const togglePopup = () => setIsPopupVisible(!isPopupVisible);

    const toggleSwitch = (sliderId) => {
        const newState = sliderId === 'slider1' ? !sound : !vibration;

        if (sliderId === 'slider1') {
            setSound(newState);
        } else {
            setVibration(newState);
        }

        const updatedSettings = {
            vibration: sliderId === 'slider2' ? newState : vibration,
            sound: sliderId === 'slider1' ? newState : sound,
        };

        fetch(`${API_URL}/api/users/${id}/settings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSettings),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Settings updated successfully:', data);
            })
            .catch((error) => {
                console.error('Error updating settings:', error);
                if (sliderId === 'slider1') {
                    setSound(!newState);
                } else {
                    setVibration(!newState);
                }
            });
    };

    return (
        <div className="info">
            <div className="container">
                <h1 className="ms-2 mt-4">{t('settings')}</h1>
            </div>
            <div className="container-fluid mt-3 px-0">
                <div className="full-width-line"></div>
            </div>
            <div className="container">
                <Link to="/Settings" className="text-decoration-none text-white">
                    <div onClick={togglePopup} className="card settings container d-flex align-items-start justify-content-center mt-3">
                        <div className="container p-0 d-flex align-items-center">
                            <img src="https://web.mgc660.com/language.svg" className="icon me-3" alt={t('language')} />
                            <h3>{t('language')}</h3>
                            <div className="ms-auto d-flex align-items-center justify-content-center">
                                <p className="currentLanguage me-2">{t('current_language')}</p>
                                <img src="https://web.mgc660.com/rightarrow.svg" className="arrow ms-auto me-1" alt="Arrow" />
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="card sound settings container d-flex flex-column justify-content-center mt-3 p-0">
                    <div className="container d-flex align-items-center">
                        <img src="https://web.mgc660.com/sound.svg" className="icon me-3" alt={t('sound')} />
                        <h3>{t('sound')}</h3>
                        <div className={`slider-container ms-auto ${sound ? 'on' : 'off'}`} onClick={() => toggleSwitch('slider1')}>
                            <div className={`slider-circle ${sound ? 'on' : 'off'}`}></div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2 mb-2 px-0">
                        <div className="full-width-line secondary"></div>
                    </div>
                    <div className="container d-flex align-items-center">
                        <img src="https://web.mgc660.com/vibration.svg" className="icon me-3" alt={t('vibration')} />
                        <h3>{t('vibration')}</h3>
                        <div className={`slider-container ms-auto ${vibration ? 'on' : 'off'}`} onClick={() => toggleSwitch('slider2')}>
                            <div className={`slider-circle ${vibration ? 'on' : 'off'}`}></div>
                        </div>
                    </div>
                </div>
                {isPopupVisible && (
                    <>
                        <div className="overlay" onClick={togglePopup}></div>
                        <div className="popup p-0">
                            <div className="container">
                                <h2 className="ms-2 mt-4">{t('language_selection')}</h2>
                            </div>
                            <div className="container d-flex flex-column align-items-center p-0">
                                {Object.keys(languageMap).map((lang) => (
                                    <div key={lang} className="container mt-3 mb-3 ms-2 d-flex align-items-center" onClick={() => updateLanguage(lang)}>
                                        <img src={`https://web.mgc660.com/languageIcons/${lang}.svg`} className="icon me-3" alt={lang} />
                                        <h4>{languageMap[lang]}</h4>
                                        {selectedLanguage === lang && <img src="https://web.mgc660.com/languageIcons/selected.svg" className="selected ms-auto me-3" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Settings;
